import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.concat.js";
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7505e514"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "fund-detail-container"
};
var _hoisted_2 = {
  class: "main"
};
import { onMounted, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useGlobalStore } from '@/store/modules/global';
import fundBasic from '@/components/fund/basic/index.vue';
import analysisList from '@/components/fund/analysisList/index.vue';
// import fundScale from '@/components/fund/scale/index.vue'
// import investmentStyle from '@/components/fund/investmentStyle/index.vue'
import navHistory from '@/components/fund/navHistory/index.vue';
import performanceCurve from '@/components/fund/performanceCurve/index.vue';
import navEsimate from '@/components/fund/navEstimate/index.vue';
export default {
  __name: 'index',
  setup: function setup(__props) {
    var route = useRoute();
    var globalStore = useGlobalStore();
    var code = route.params.code;
    var basic = reactive({
      name: ''
    });
    var activeNames = ref(['esimate', 'basic', 'navHistory',
    // 'scale',
    'analysis', 'performance'
    // 'investmentStyle',
    ]);

    var changeCollapse = function changeCollapse(activeNames) {
      if (activeNames.includes('scale')) {
        // if (!fundDetailData.scale.isReady) {
        //    // getScaleData()
        // }
      }
      // if (activeNames.includes('analysis')) {
      //     if (!fundDetailData.analysis.isReady) {
      //         getAnalysisDate()
      //     }
      // }
    };

    var changeName = function changeName(val) {
      document.title = "\u4EA7\u54C1\u8BE6\u60C5\uFF1A".concat(val.basic.name, "(").concat(code, ")");
      globalStore.setPageTitle(document.title);
      basic.name = val.basic.name;
    };
    onMounted(function () {});
    return function (_ctx, _cache) {
      var _component_van_collapse_item = _resolveComponent("van-collapse-item");
      var _component_van_collapse = _resolveComponent("van-collapse");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_collapse, {
        modelValue: activeNames.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return activeNames.value = $event;
        }),
        id: "collapse",
        onChange: changeCollapse
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_van_collapse_item, {
            title: "产品概况",
            name: "basic"
          }, {
            default: _withCtx(function () {
              return [_createVNode(fundBasic, {
                code: _unref(code),
                onChangeName: changeName
              }, null, 8, ["code"])];
            }),
            _: 1
          }), _createVNode(_component_van_collapse_item, {
            title: "净值估算",
            name: "esimate"
          }, {
            default: _withCtx(function () {
              return [_createVNode(navEsimate, {
                codes: [_unref(code)],
                showSmartMoney: true,
                showGold: true,
                onChangeName: changeName
              }, null, 8, ["codes"])];
            }),
            _: 1
          }), _createVNode(_component_van_collapse_item, {
            title: "业绩曲线（最近一年）",
            name: "performance"
          }, {
            default: _withCtx(function () {
              return [_createVNode(performanceCurve, {
                codes: [_unref(code)],
                showSmartMoney: true
              }, null, 8, ["codes"])];
            }),
            _: 1
          }), _createVNode(_component_van_collapse_item, {
            title: "历史净值（最近一年）",
            name: "navHistory"
          }, {
            default: _withCtx(function () {
              return [_createVNode(navHistory, {
                codes: [_unref(code)],
                showSmartMoney: false
              }, null, 8, ["codes"])];
            }),
            _: 1
          }), _createVNode(_component_van_collapse_item, {
            title: "指标分析",
            name: "analysis"
          }, {
            default: _withCtx(function () {
              return [_createVNode(analysisList, {
                code: _unref(code)
              }, null, 8, ["code"])];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["modelValue"])])]);
    };
  }
};